<template>
  <div
    class="p-relative"
    :class="loading ? 'opacity-70' : ''"
  >
    <div class="body-container txt-black">
      <p class="fs-24 fw-500 mb-3 txt-pri text-center">PHIẾU TƯ VẤN</p>
      <p class="mb-2 fs-14 fw-400 fst-italic txt-neutral-700 text-right">
        Ngày giờ: {{ getDateTime(form && form.start_time) }}
      </p>
      <p class="fs-18 fw-700 txt-pri mb-0">
        {{ !person ? form && form.patient_name : person && person.name }}
      </p>
      <p class="fs-14 txt-neutral-450 fw-400">
        <span class="mr-3 fw-500">{{
          !person
            ? (form && form.gender === 1 ? "Nam" : "Nữ") || ""
            : (person && person.gender === 1 ? "Nam" : "Nữ") || ""
        }}</span>
        {{
          !person
            ? form && form.birthday
              ? form && form.birthday
              : ""
            : person && person.birthday
            ? getAges(person.birthday)
            : ""
        }}
        {{ !person ? (form && form.career) || "" : (person && person.career) || "" }}
      </p>

      <p class="fs-16 fw-400 mb-0 txt-neutral-700">
        SĐT: {{ !person ? form && form.phone : person && person.phone }}
      </p>
      <p class="fs-16 fw-400 mb-0 txt-neutral-700">
        Địa chỉ: {{ !person ? form && form.address : person && person.address }}
      </p>
      <p class="fs-16 fw-400 mb-4 txt-neutral-700">
        Người liên hệ: {{ (form && form.contact_name) || "" }}
        {{
          form && form.contact_relationship
            ? ", " + form.contact_relationship
            : ""
        }}
        {{ form && form.contact_phone ? ", SĐT: " + form.contact_phone : "" }}
      </p>
      <!-- <p class="fs-16 fw-500 mb-2">Thời gian</p> -->
      <!-- <div class="col-lg-8 col-md-7 col-xl-9 mb-12">
                    <div class="row">
                        <div class="col-md-6 d-flex">
                            <input
                            type="date"
                            class="form-control w-50 border radius-10 mr-2"
                            :value="getDate(form && form.start_time)"
                            >
                            <input
                            type="time"
                            class="form-control w-50 border radius-10"
                            :value="getTime(form && form.start_time)"
                            > 
                        </div>
                    </div>
                </div> -->
      <div class="mb-24">
        <p class="txt-neutral-500 fs-16 fw-500 mb-2">Lý do khám bệnh</p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="2"
          class="form-control form-control-bg bg-form txt-pri fs-16 fw-400"
          placeholder="Tư vấn quá trình điều trị"
          v-model="form.reason"
        >
        </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500 fs-16 fw-500 mb-2">Diễn biến</p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control form-control-bg bg-form txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.progression"
        >
        </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500 fs-16 fw-500 mb-2">Kết quả xét nghiệm</p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="4"
          class="form-control form-control-bg bg-form txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.prehistoric"
        >
        </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500 fs-16 fw-500 mb-2">Chẩn đoán</p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control form-control-bg bg-form txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.diagnose"
        >
        </textarea>
      </div>
      <div class="mb-24">
        <p class="txt-neutral-500 fs-16 fw-500 mb-2">Chỉ định</p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="2"
          class="form-control form-control-bg bg-form txt-pri fs-16 fw-400"
          placeholder="Nhập nội dung ..."
          v-model="form.indication"
        >
        </textarea>
      </div>
      <div>
        <TempPhieuTuVan
          v-if="appt"
          :form="form"
          :person="person"
          :update_id="(appt.consultation && appt.consultation.id) || null"
          :is_create="!appt.consultation"
          :is_update="!!appt.consultation"
          @saveFile="saveFileComplete"
        ></TempPhieuTuVan>
      </div>
      <div
        class="p-center d-flex justify-content-center align-items-center"
        v-if="loading"
      >
        <span
          class="spinner-border spinner-border-sm mr-2"
          role="status"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import TempPhieuTuVan from '../../templates/PhieuTuVan/PhieuTuVan.vue'
export default {

  name: 'FormPhieuTuVan',
  components: { TempPhieuTuVan },
  props: ['appt'],
  data () {
    return {
      form: {
        progression: '',
        diagnose: '',
        indication: '',
        reason: '',
        prehistoric: '',
        patient_name: '',
        birthday: '',
        career: '',
        gender: '',
        address: '',
        phone: '',
        contact_name: '',
        contact_phone: '',
        contact_relationship: '',
        start_time: window.moment(),
        city: '',
        appt_id: null,
        person_diseases_id: null,
        notes: '',
        profile_number: '',
        doctor: null,
        person_id: 0
      },
      loading: false,
      person: null
    }
  },
  watch: {
    appt: {
      handler: function (appt, old) {
        this.handlerAppt()
      },
      deep: true
    }
  },
  mounted () {
    if (this.appt) this.handlerAppt()
  },
  methods: {
    handlerAppt () {
      if (!this.appt) return
      if (this.appt.person_diseases) this.hanlderDisease()
      if (this.appt.consultation) this.hanlderCons()
      this.person = this.appt.person
      this.form.appt_id = this.appt.id
      this.form.patient_name = this.person?.name
      this.form.person_id = this.person?.id
      this.form.birthday = this.person?.birthday ? this.getAges(this.person?.birthday) : ''
      this.form.career = this.person?.career
      this.form.gender = this.getGender(this.person?.gender)
      this.form.address = this.person?.address
      this.form.phone = this.person?.phone
    },
    hanlderDisease () {
      this.form.contact_name = this.disease?.contact_name
      this.form.contact_phone = this.disease?.contact_phone
      this.form.contact_relationship = this.disease?.contact_relationship
      this.form.person_diseases_id = this.disease?.id
      this.form.profile_number = this.disease?.profile_number
    },
    hanlderCons () {
      let consultation = this.appt.consultation
      this.form.progression = consultation.progression
      this.form.diagnose = consultation.diagnose
      this.form.indication = consultation.indication
      this.form.prehistoric = consultation.prehistoric
      this.form.reason = consultation.reason
      this.form.notes = consultation.notes
      this.form.start_time = window.moment(consultation.start_time)
      if (consultation.appointment && consultation.appointment.doctor) {
        this.form.doctor = consultation.appointment && consultation.appointment.doctor
      }
    },
    showModalPrint (show) {
      window.$('#modal__print').modal(show ? 'show' : 'hide')
    },
    getGender (gender = 1) {
      return gender === 1 ? 'Nam' : gender === 2 ? 'Nữ' : gender === 3 ? 'Khác' : ''
    },
    getAges (date) {
      let bd = window.moment(date)
      let age = window.moment().diff(bd, 'years')
      return bd.format('DD/MM/YYYY') + ' (' + age + ' tuổi)'
    },
    getDate (date) {
      return window.moment(date).format('YYYY-MM-DD')
    },
    getTime (date) {
      return window.moment(date).format('HH:mm')
    },
    getDateTime (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    saveFileComplete (data) {
      this.$emit('saveFileComplete', data)
    }
  }
}
</script>
<style lang="css" scoped>
.body-container {
  padding: 16px 32px 16px 32px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-12 {
  margin-bottom: 24px;
}
.opacity-70 {
  opacity: 0.7;
}
.txt-neutral-450 {
  color: #5d5d5d;
}
.txt-neutral-700 {
  color: #292929;
}
.txt-neutral-500 {
  color: #424242;
}
.bg-form {
  background-color: #f2f5fa;
}
::placeholder {
  color: #c4c4c4;
  font-size: 16px;
  font-weight: 400px;
}
</style>