<template>
  <div>
    <el-dialog
      title="Hình ảnh căn cước công dân"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <div v-loading="loading">
        <div class="row">
          <div class="col-md-6 flex-column d-flex items-center">
            <div class="text-black fs-20">Mặt trước</div>
            <img
              @click="showImage(0)"
              class="cursor-pointer"
              :src="frontSideUrl"
            />
          </div>
          <div class="col-md-6 flex-column d-flex items-center">
            <div class="text-black fs-20">Mặt sau</div>
            <img
              @click="showImage(1)"
              class="cursor-pointer"
              :src="backSideUrl"
            />
          </div>
        </div>

        <div class="flex justify-end mt-4">
          <el-button size="medium" @click="close">Thoát</el-button>
        </div>
      </div>
      <CoolLightBox
        v-if="images"
        :items="images"
        :index="image_index"
        :useZoomBar="true"
        @close="image_index = null"
      >
      </CoolLightBox>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'ModalViewIdentityCard',
  data () {
    return {
      dialogVisible: false,
      loading: false,
      image_index: null
    }
  },
  props: {
    frontSideUrl: String,
    backSideUrl: String
  },
  computed: {
    images () {
      return [this.frontSideUrl, this.backSideUrl]
    }
  },
  methods: {
    showImage (index) {
      this.image_index = index
    },
    close () {
      this.dialogVisible = false
    },
    openDialog () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
