<template>
  <div class="flex flex-col md:flex-row items-center space-x-2 w-full">
    <template v-if="showSelectDate">
      <div class="md:w-1/2 w-full">
        <label class="text-xs font-bold text-gray-900 mb-0 w-full">
          <span class="mb-2">{{ isDateRange ? $t("Từ ngày") : $t("Ngày") }}</span>
          <span class="hodo-el block el-input el-input--suffix">
            <input
              name="fromDate"
              v-model="form.fromDate"
              type="date"
              class="el-input__inner pr-3 bg-white border border-gray-200"
              :placeholder="$t('Chọn ngày')"
              :max="maxDateValue"
              @change="handleFilters"
            />
          </span>
        </label>
      </div>
      <div class="md:w-1/2 w-full" v-if="!!isDateRange">
        <label class="text-xs font-bold text-gray-900 mb-0 w-full">
          <span class="mb-2">{{ $t("Đến ngày") }}</span>
          <span class="hodo-el block el-input el-input--suffix">
            <input
              name="toDate"
              v-model="form.toDate"
              type="date"
              class="el-input__inner pr-3 bg-white border border-gray-200"
              :placeholder="$t('Chọn ngày')"
              :max="maxDateValue"
              @change="handleFilters"
            />
          </span>
        </label>
      </div>
    </template>
  </div>
</template>

<style lang="css" scoped>
.hodo-el /deep/ .el-input__inner {
  background: white !important;
  border: 1px solid #dee2e6 !important;
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'CustomFilters',
  props: {
    searchBoxClass: {
      type: String,
      default: 'w-1/2'
    },
    isRefresh: {
      type: Boolean,
      default: false
    },
    showRoom: {
      type: Boolean,
      default: true
    },
    showPayment: {
      type: Boolean,
      default: true
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    showDoctor: {
      type: Boolean,
      default: true
    },
    showSearchBox: {
      type: Boolean,
      default: true
    },
    showSelectDate: {
      type: Boolean,
      default: true
    },
    showQuickDate: {
      type: Boolean,
      default: true
    },
    customStatusData: {
      type: Object
    },
    defaultFilters: {
      type: Object
    },
    customQuickDate: {
      type: Object
    },
    isMaxDateToday: {
      type: Boolean,
      default: false
    },
    isDateRange: {
      type: Boolean,
      default: true
    },
    doctorId: {
      type: Number
    }
  },
  data () {
    return {
      today: this.moment(),
      form: {
        search: '',
        fromDate: '',
        toDate: ''
      },
      formData: null,
      typing: false,
      debounce: null
    }
  },
  watch: {
    customStatusData (data) {
      if (data) {
        this.form = {
          ...this.form,
          status: {
            ...this.form.status,
            value: data.value
          }
        }
      }
    },
    isRefresh (value) {
      if (value) {
        this.form = {
          ...this.form,
          search: '',
          fromDate: this.moment().format('YYYY-MM-DD'),
          toDate: this.moment().format('YYYY-MM-DD'),
          status: {
            ...this.form.status,
            value: this.customStatusData?.value || 'All'
          }
        }
      }
    },
    consultingRooms (data) {
      if (data) {
        this.form.room = {
          options: [
            {
              value: 'All',
              label: this.$t('Tất cả')
            }
          ],
          value: 'All'
        }
        _.forEach(data, item => {
          this.form.room.options.push({
            value: item?.id,
            label: item?.name
          })
        })
      }
    },
    clinicDoctors (data) {
      if (data) {
        this.form.doctor = {
          options: [
            {
              value: 'All',
              label: this.$t('Tất cả')
            }
          ],
          value: 'All'
        }
        _.forEach(data, item => {
          this.form.doctor.options.push({
            value: item?.doctor_id,
            label: item?.doctor?.name
          })
        })
      }
    },
    defaultFilters (data) {
      console.log(data)
      if (data) {
        this.form = {
          fromDate: data.fromDate,
          toDate: data.toDate
        }
      }
    }
  },
  mounted () {
    if (this.defaultFilters) {
      this.form.fromDate = this.defaultFilters.fromDate
      this.form.toDate = this.defaultFilters.toDate
    }
  },
  computed: {
    maxDateValue () {
      return this.isMaxDateToday ? new Date().toISOString().split('T')[0] : ''
    }
  },
  methods: {
    handleFilters () {
      this.formData = {
        fromDate: this.form.fromDate,
        toDate: this.isDateRange ? this.form.toDate : this.form.fromDate
      }

      this.$emit('setFilters', this.formData)
    },
    resetDate () {
      this.form.fromDate = this.moment().format('YYYY-MM-DD')
      this.form.toDate = this.moment().format('YYYY-MM-DD')
    },
    handleUpdateDate (value) {
      this.resetDate()

      if (value) {
        if (value === 0.5) {
          // value = 0.5 => add 1 week
          this.form = {
            ...this.form,
            toDate: this.moment()
              .add(1, 'weeks')
              .format('YYYY-MM-DD')
          }
        } else {
          this.form = {
            ...this.form,
            toDate: this.moment()
              .add(value, 'months')
              .format('YYYY-MM-DD')
          }
        }
      } else {
        this.form = {
          ...this.form,
          toDate: this.moment().format('YYYY-MM-DD')
        }
      }

      this.handleFilters()
    },
    debounceSearch () {
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.formData = {
          ...this.formData,
          search: this.form.search
        }
        this.$emit('setFilters', this.formData)
      }, 600)
    }
  }
}
</script>
