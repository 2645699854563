<template>
  <el-dialog
    :title="$t('lbl_accept_connection_request')"
    :visible.sync="dialogVisible"
    width="25%"
    :before-close="handleClose"
  >
    <p
      style="word-break: break-word;"
    >{{ $t('lbl_are_you_sure_you_want_to_connect_the_hssk_and_badt_information') }}</p>
    <div class="d-flex w-50" style="justify-content: end; margin-left: auto;">
      <button class="btn bd-pri w-100 cl-pri robo-16-400" @click="handleClose">
        <span class="robo-16-500">{{ $t('lbl_cancel') }}</span>
      </button>
      <button class="btn bg-pri bd-pri w-100 text-white ml-2 robo-16-400" @click="confirm">
        <span class="robo-16-500">{{ $t('lbl_accept') }}</span>
      </button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    openDialog () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    confirm () {
      this.$emit('confirm')
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
}
</style>>