<template>
  <div>
    <div class="modal" role="dialog" id="modal--care-plan">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("obj_health_records.lbl_health_record") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div style="padding: 10px 15px">
                  <div class="row">
                    <div class="form-group mb-2">
                      <div class="row mb-3">
                        <div class="col-sm-6">
                          <label class="robo-20-500 txt-grey-600 required">
                            {{ $t("obj_health_records.lbl_patient_for_me") }}
                          </label>
                        </div>
                        <div
                          class="col-sm-6 flex flex-col md:flex-row justify-start md:justify-end mt-1"
                        >
                          <button
                            v-if="can('create-new-patient')"
                            class="btn radius-0 bg-pri bd-pri text-white border-0"
                            type="button"
                            @click="onShowModalAddPersons()"
                          >
                            <span class="fa fa-plus mr-2"></span>
                            {{ $t("obj_health_records.lbl_add_patient") }}
                          </button>
                        </div>
                      </div>
                      <v-select
                        v-if="
                          persons && persons.data && can('view-patient-list')
                        "
                        class="mt-2"
                        :options="persons.data"
                        label="name"
                        :placeholder="
                          $t('obj_health_records.lbl_enter_keywork_search')
                        "
                        :filterable="false"
                        @open="onOpen"
                        @close="onClose"
                        v-model="data"
                        transition
                        @search="searchPerson"
                      >
                        <div slot="no-options">
                          {{ $t("obj_health_records.lbl_data_not_found") }}
                        </div>
                        <template v-slot:option="item">
                          <span class="font-weight-bold"
                            >{{ item.name }} - {{ item.phone }}</span
                          >
                        </template>
                        <template #list-footer>
                          <li ref="load" v-show="hasNextPage"></li>
                        </template>
                      </v-select>
                      <div v-if="!can('view-patient-list')">
                        <p class="mb-0 robo-16-500 text-red">
                          {{ $t("obj_health_records.lbl_not_permission_view") }}
                        </p>
                      </div>
                      <small v-if="!data && is_submit" class="text-warning">
                        {{ $t("obj_health_records.lbl_not_empty") }}
                      </small>
                    </div>
                    <div class="mb-2">
                      <label class="robo-20-500 txt-grey-600">
                        {{ $t("obj_health_records.lbl_use_example") }}
                      </label>
                      <v-select
                        v-if="templates && templates.data"
                        class="mt-2"
                        :options="templates.data"
                        label="diseases_name"
                        :placeholder="$t('obj_health_records.lbl_custom')"
                        :filterable="false"
                        @open="onOpenTemp"
                        @close="onCloseTemp"
                        v-model="slt_temp"
                        transition
                        @search="searchTemp"
                      >
                        <div slot="no-options">
                          {{ $t("obj_health_records.lbl_not_example") }}
                        </div>
                        <template #list-footer>
                          <li ref="load" v-show="tempHasNextPage"></li>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer text-right">
            <button
              :disabled="loading"
              v-if="can('create-new-patient')"
              class="btn radius-0 bg-pri bd-pri text-white border-0"
              type="button"
              @click="onSubmit"
            >
              <span class="fa fa-plus mr-2"></span>
              {{ $t("obj_health_records.lbl_create") }}
              <span
                class="spinner-border spinner-border-sm mr-2"
                role="status"
                v-if="loading"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
export default {
  name: 'ModalCarePlan',
  props: ['persons', 'slt_person_id', 'loading', 'new_person'],
  components: { vSelect },
  watch: {
    slt_person_id: function (_new, old) {
      if (_new) {
        this.changeSltPerson(_new)
      }
    },
    new_person: function (_new) {
      if (_new) {
        this.data = _new
      }
    }
  },
  data () {
    return {
      is_submit: false,
      observer: null,
      observerTemp: null,
      data: null,
      slt_temp: null,
      templates: []
    }
  },
  computed: {},
  mounted () {
    let self = this
    self.observer = new IntersectionObserver(self.infiniteScroll)
    self.observerTemp = new IntersectionObserver(self.infiniteScrollTemp)
    self.getTemplates(20)
    window.$(document).ready(function () {
      window.$('#modal--care-plan').on('hide.bs.modal', function (e) {
        self.data = null
      })
    })
  },
  methods: {
    hasNextPage () {
      let self = this
      if (self.persons.total > self.persons.count) {
        return true
      }
      return false
    },
    changeSltPerson (id) {
      let self = this
      let person = self.persons.data.find((dr_person) => dr_person.id === id)
      if (person) {
        self.data = person
      }
    },

    async onSubmit () {
      let self = this
      try {
        self.is_submit = true
        if (self.data) {
          self.$emit('onSubmit', self.data, self.slt_temp)
          self.is_submit = false
        }
      } catch (e) {
        console.log(e)
      }
    },
    async onOpen () {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose () {
      this.observer.disconnect()
    },
    searchPerson (search) {
      let self = this
      if (search) {
        self.$emit('searhPerson', search)
      } else {
        self.$emit('searchPersonDelete')
      }
    },
    async infiniteScroll ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var params = {
          limit: this.persons.count + 20
        }
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.$emit('onRefresh', params.limit)
      }
    },
    onShowModalAddPersons () {
      let self = this
      self.$emit('onShowModalAddPersons', true)
    },
    tempHasNextPage () {
      let self = this
      if (self.templates.total > self.templates.count) {
        return true
      }
      return false
    },
    async onOpenTemp () {
      if (this.tempHasNextPage) {
        await this.$nextTick()
        this.observerTemp.observe(this.$refs.load)
      }
    },
    onCloseTemp () {
      this.observerTemp.disconnect()
    },
    searchTemp (search) {
      let self = this
      self.getTemplates(20, search)
    },
    async infiniteScrollTemp ([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const scrollTop = target.offsetParent.scrollTop
        var params = {
          limit: this.templates.count + 20
        }
        await this.$nextTick()
        ul.scrollTop = scrollTop
        this.$emit('onRefresh', params.limit)
      }
    },
    async getTemplates (limit, search) {
      let self = this
      if (!this.$clinicId) return
      let params = {
        limit
      }
      if (search) {
        params.search = search
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getTemplates(this.$clinicId, params)
        .then((res) => {
          self.templates = res.data
        })
    }
  }
}
</script>
<style scoped>
input[type="text"] {
  height: 60px;
  color: #20419b;
  font-weight: 500px;
}
</style>