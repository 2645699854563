<template>
  <div>
    <el-dialog
      title="Thêm mới Bệnh Nhân"
      :visible.sync="dialogVisible"
      width="80%"
      @close="handleClose"
    >
      <div v-loading="loading">
        <div class="row">
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">
              Họ và tên
              <span class="text-red-500">*</span>
            </div>
            <input class="form--input uppercase" type="text" v-model="form.name" />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700">CCCD/ID</div>
            <input
              @blur="(e)=>{
                const params={
                  value:e.target.value,
                  key:'cmnd'
                }
                handleCheckExist(params)
            }"
              class="form--input"
              type="text"
              v-model="form.cmnd"
            />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Mã Bệnh nhân(PID)</div>
            <input
              @blur="(e)=>{
                const params={
                  value:e.target.value,
                  key:'pid'
                }
                handleCheckExist(params)
            }"
              class="form--input"
              v-model="form.pid"
            />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Mã Điều trị</div>
            <input
              @blur="(e)=>{
                const params={
                  value:e.target.value,
                  key:'so_ho_so'
                }
                handleCheckExist(params)
            }"
              class="form--input"
              v-model="form.doc_no"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">
              SĐT
              <span class="text-red-500">*</span>
            </div>
            <input
              @blur="(e)=>{
                const params={
                  value:e.target.value,
                  key:'phone'
                }
                handleCheckExist(params)
            }"
              class="form--input"
              type="number"
              v-model="form.phone"
            />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">
              Giới tính
              <span class="text-red-500">*</span>
            </div>
            <div class="mt-2">
              <el-radio-group v-model="form.gender">
                <el-radio :label="1">Nam</el-radio>
                <el-radio :label="2">Nữ</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">
              Ngày sinh
              <span class="text-red-500">*</span>
            </div>
            <input class="form--input" type="date" v-model="form.birthday" />
          </div>

          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Ngày vào viện</div>
            <input class="form--input" type="datetime-local" v-model="form.admission_hospital_date" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Quốc tịch</div>
            <input class="form--input" type="text" v-model="form.nationality_name" />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Nơi làm việc</div>
            <input class="form--input" type="text" v-model="form.work_at" />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Dân tộc</div>
            <input class="form--input" type="text" v-model="form.ethnic_name" />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Ngoại kiều</div>
            <input class="form--input" type="text" v-model="form.foreigner" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Tỉnh/Thành Phố</div>
            <el-select
              class="select-cs"
              filterable
              allow-create
              v-model="form.address_province_name"
              placeholder="Chọn tỉnh/thành phố"
              @change="handleSelectProvince"
            >
              <el-option
                v-for="item in provinces"
                :key="item.province_id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <!-- <input class="form--input" type="text" v-model="form.address_province_name" /> -->
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Quận/Huyện</div>
            <el-select
              class="select-cs"
              filterable
              allow-create
              v-model="form.address_district_name"
              placeholder="Chọn quận/huyện"
              @focus="handleGetDistricts"
              @change="handleSelectDistrict"
            >
              <el-option
                v-for="item in districts"
                :key="item.district_id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <!-- <input class="form--input" type="text" v-model="form.address_district_name" /> -->
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Xã/Phường</div>
            <el-select
              class="select-cs"
              filterable
              allow-create
              v-model="form.address_wards_name"
              placeholder="Chọn xã/phường"
              @focus="handleGetWards"
            >
              <el-option
                v-for="item in wards"
                :key="item.ward_id"
                :name="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
            <!-- <input class="form--input" type="text" v-model="form.address_wards_name" /> -->
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Địa chỉ</div>
            <input class="form--input" type="text" v-model="form.address" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Người nhà</div>
            <input class="form--input" type="text" v-model="form.emergency_contact" />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">SĐT người nhà</div>
            <input class="form--input" type="text" v-model="form.contact_phone" />
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Nghề nghiệp</div>
            <input class="form--input" type="text" v-model="form.career" />
          </div>
        </div>
        <div class="row flex items-center">
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Đối tượng BHYT</div>
            <el-select class="select-cs" v-model="form.insurance_type" placeholder="Chọn">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="col-md-3">
            <div class="text-black fs-14 fw-700 required">Số BHYT</div>
            <input
              :disabled="!isSelectedBHYT"
              class="form--input"
              type="text"
              v-model="form.insurance_number"
            />
          </div>
          <div class="col-md-2">
            <div class="text-black fs-14 fw-700 required">Ngày bắt đầu</div>
            <input
              :disabled="!isSelectedBHYT"
              class="form--input"
              type="date"
              v-model="form.insurance_start_date"
            />
          </div>
          <div class="col-md-2">
            <div class="text-black fs-14 fw-700 required">Hết hạn</div>
            <input
              :disabled="!isSelectedBHYT"
              class="form--input"
              type="date"
              v-model="form.insurance_expire"
            />
          </div>
          <div class="col-md-2">
            <div class="text-black fs-14 fw-700 required">Mức hưởng (%)</div>
            <input
              :disabled="!isSelectedBHYT"
              class="form--input"
              type="number"
              v-model="form.insurance_rate"
            />
          </div>
        </div>
        <div class="flex justify-end mt-2 gap-2">
          <el-button size="medium" @click="close">Hủy</el-button>
          <!-- <el-tooltip
            :disabled="!isExist"
            content="Bệnh nhân đã tồn tại trong hệ thống"
            placement="left"
          >-->
          <button
            :disabled="loading"
            size="medium"
            class="btn-pri bg-pri btn text-white"
            @click="submit"
          >Lưu</button>
          <!-- </el-tooltip> -->
        </div>
      </div>
    </el-dialog>
    <ModalSamePerson
      ref="ModalSamePerson"
      @onClose="()=>{
        isShowSamePersonModal=false
      }"
      @reUsePerson="reUsePerson"
    />
  </div>
</template>
<script>
// TODO
// import appUtils from '../../utils/appUtils'
import ModalSamePerson from './ModalSamePerson.vue'
import { ADDRESS_TYPE } from '../../utils/constants'
export default {
  name: 'ModalAddPersonV2',
  components: {
    ModalSamePerson
  },
  data () {
    const defaultForm = {
      name: '',
      national_id: '',
      phone: null,
      birthday: '',
      gender: '',
      career: '',
      foreigner: '',
      address: '',
      address_district_name: '',
      address_province_name: '',
      address_wards_name: '',
      work_at: '',
      insurance_type: '',
      insurance_expire: '',
      insurance_start_date: '',
      emergency_contact: '',
      contact_phone: '',
      pid: '',
      doc_no: '',
      admission_hospital_date: '',
      // TODO
      // nationality: '',
      // insurance_rate: null,
      // insurance_no: '',
      // ethnicity: ''
      // clinic_id: appUtils.getClinicId(),
      // ws_id: appUtils.getClinicId(),
      // org_id: appUtils.getOrgId()
      ethnic_name: '',
      insurance_number: '',
      nationality_name: '',
      insurance_rate: '',
      isShowSamePersonModal: false
    }

    const DEFAULT_EXIST = [
      {
        key: 'phone',
        isExist: false
      },
      {
        key: 'cmnd',
        isExist: false
      },
      {
        key: 'pid',
        isExist: false
      },
      {
        key: 'so_ho_so',
        isExist: false
      }
    ]

    return {
      isRequestSamePerson: true,
      dialogVisible: false,
      loading: false,
      options: [
        {
          value: '1',
          label: 'BHYT'
        },
        {
          value: '2',
          label: 'Thu phí'
        },
        {
          value: '3',
          label: 'Miễn'
        },
        {
          value: '4',
          label: 'Khác'
        }
      ],
      defaultForm,
      form: defaultForm,
      arrExist: [...DEFAULT_EXIST],
      DEFAULT_EXIST,
      provinces: [],
      districts: [],
      wards: [],
      selectedAddress: {}
    }
  },
  created () {
    this.handleGetProvinces()
  },
  computed: {
    isSelectedBHYT () {
      return this.form.insurance_type === this.options[0].value
    },
    isExist () {
      return this.arrExist?.some(item => item.isExist)
    }
  },
  methods: {
    reUsePerson (selectedPerson) {
      this.isRequestSamePerson = false
      this.arrExist = [...this.DEFAULT_EXIST]
      this.form = {
        ...selectedPerson,
        insurance_expire: selectedPerson?.id
          ? selectedPerson.insurance_expire
          : window
            .moment(selectedPerson.insurance_expire, 'DD/MM/YYYY')
            .format('YYYY-MM-DD'),
        insurance_start_date: selectedPerson.insurance_start_date
          ? selectedPerson?.id
            ? selectedPerson.insurance_start_date
            : window
              .moment(selectedPerson.insurance_start_date, 'DD/MM/YYYY')
              .format('YYYY-MM-DD')
          : '',
        birthday: window.moment(selectedPerson.birthday).format('YYYY-MM-DD'),
        admission_hospital_date: selectedPerson.admission_hospital_date
          ? window
            .moment(selectedPerson.admission_hospital_date)
            .format('YYYY-MM-DD HH:mm:ss')
          : '',
        phone:
          typeof selectedPerson.phone === 'string'
            ? selectedPerson.phone.replaceAll('.', '').toString()
            : selectedPerson.phone,
        emergency_contact:
          typeof selectedPerson.emergency_contact === 'string'
            ? selectedPerson.emergency_contact.replaceAll('.', '').toString()
            : selectedPerson.emergency_contact
      }

      console.log(Number(selectedPerson.phone.replace('.', '')))
    },
    async blurEventHandlerCCCD (e) {
      const cccd = e.target.value
      if (cccd && this.isRequestSamePerson) {
        const params = {
          cmnd: cccd
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .checkExistPatient(params)
          .then(resp => {
            if (resp) {
              if (resp.data.data.length > 0) {
                this.$refs.ModalSamePerson.openDialog(resp.data.data)
              }
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {})
      }
    },
    async blurEventHandlerPhone (e) {
      const phone = e.target.value
      if (phone && this.isRequestSamePerson) {
        const params = {
          phone: phone
        }
        await this.$rf
          .getRequest('DoctorRequest')
          .checkExistPatient(params)
          .then(resp => {
            if (resp) {
              if (resp.data.data.length > 0) {
                this.$refs.ModalSamePerson.openDialog(resp.data.data)
              }
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {})
      }
    },
    async handleCheckExist (params) {
      const newParams = {
        ...params,
        page_num: 1,
        page_size: 1000
      }

      if (!newParams.value || this.isShowSamePersonModal) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .checkExistPatient(newParams)
        if (response.data?.data?.length) {
          this.$refs.ModalSamePerson.openDialog(response.data.data)
          this.isShowSamePersonModal = true
          this.arrExist = this.arrExist.map(item => {
            if (item?.key === newParams.key) {
              return {
                key: item?.key,
                isExist: true
              }
            } else {
              return item
            }
          })
        } else {
          this.arrExist = this.arrExist.map(item => {
            if (item?.key === newParams.key) {
              return {
                key: item?.key,
                isExist: false
              }
            } else {
              return item
            }
          })
        }
      } catch (error) {
        console.log(error)
        this.arrExist = this.arrExist.map(item => {
          if (item?.key === newParams.key) {
            return {
              key: item?.key,
              isExist: false
            }
          } else {
            return item
          }
        })
      }
    },
    close () {
      this.dialogVisible = false
      this.form = this.defaultForm
      console.log(this.form)
    },
    submit () {
      const self = this

      if (
        !self.form.name ||
        // !self.form.cmnd ||
        !self.form.birthday ||
        !self.form.phone
      ) {
        self.$message({
          message: self.$t(
            'Vui lòng điền đầy đủ thông tin: Họ và tên, Giới tính, SĐT, Ngày sinh, CCCD'
          ),
          type: 'error'
        })
        return
      }

      if (!Number(self.form.phone)) {
        self.$message({
          message: self.$t('Số điện thoại không hợp lệ'),
          type: 'error'
        })
        return
      }

      if (self.form.birthday) {
        const _birthday = window.moment(self.form.birthday)
        if (
          String(_birthday.year()).length > 4 ||
          _birthday.year() > window.moment().year() ||
          isNaN(_birthday.year())
        ) {
          self.$message({
            message: self.$t('Năm sinh nhập không hợp lệ'),
            type: 'error'
          })
          return
        }
        if (_birthday - window.moment() > 0) {
          self.$message({
            message: self.$t('Ngày sinh nhập không hợp lệ'),
            type: 'error'
          })
          return
        }
      }
      // if (self.form.insurance_expire) {
      //   const _insurance_expire = window.moment(self.form.insurance_expire)
      //   if (
      //     String(_insurance_expire.year()).length > 4 ||
      //     _insurance_expire.year() < window.moment().year() ||
      //     isNaN(_insurance_expire.year())
      //   ) {
      //     self.$message({
      //       message: self.$t('Năm hết hạn BHXH nhập không hợp lệ'),
      //       type: 'error'
      //     })
      //     return
      //   }
      // }

      // if (this.isExist) {
      //   self.$message({
      //     message: self.$t('Bệnh nhân đã tồn tại trên hệ thống'),
      //     type: 'error'
      //   })

      //   return
      // }

      if (this.form.id) {
        this.$confirm(
          'Dữ liệu cũ của bệnh nhân sẽ được cập nhật, bạn có muốn cập nhật không?',
          this.$t('Cảnh báo')
        )
          .then(_ => {
            this.updatePerson()
          })
          .catch(_ => {})
      } else {
        this.postPerson()
      }
    },
    async updatePerson () {
      const self = this
      const params = {
        clinic_id: self.$globalClinic.id,
        name: self.form.name.toUpperCase(),
        national_id: self.form.national_id,
        cmnd: self.form.cmnd,
        phone: self.form.phone,
        gender: self.form.gender,
        career: self.form.career,
        foreigner: self.form.foreigner,
        address: self.form.address,
        address_district_name: self.form.address_district_name,
        address_province_name: self.form.address_province_name,
        address_wards_name: self.form.address_wards_name,
        work_at: self.form.work_at,
        insurance_type: self.form.insurance_type,
        insurance_start_date: self.form.insurance_start_date,
        emergency_contact: self.form.emergency_contact,
        contact_phone: self.form.contact_phone,
        insurance_rate: self.form.insurance_rate,
        pid: self.form.pid,

        // TODO
        // birthday: window.moment(self.form.birthday, 'YYYY-MM-DD').unix(),
        // ethnicity: self.form.ethnic_name,
        // insurance_no: self.form.insurance_number,
        // insurance_expire: window
        //   .moment(self.form.insurance_expire, 'YYYY-MM-DD')
        //   .unix(),
        // nationality: self.form.nationality_name,
        // admission_hospital_date: window
        //   .moment(self.form.admission_hospital_date, 'YYYY-MM-DD')
        //   .unix()
        birthday: self.form.birthday,
        ethnic_name: self.form.ethnic_name,
        insurance_number: self.form.insurance_number,
        insurance_expire: self.form.insurance_expire,
        nationality_name: self.form.nationality_name,
        admission_hospital_date: self.form.admission_hospital_date,
        doc_no: String(this.form.doc_no)
      }
      self.loading = true
      await self.$rf
        .getRequest('DoctorRequest')
        .updatePersonInfoV3(this.form.id, params)
        // TODO
        // .updatePatient(this.form.id, params)
        .then(resp => {
          if (resp) {
            self.$emit('refresh', resp.data)
            self.$message({
              message: 'Update user thành công',
              type: 'success'
            })
            self.close()
          }
        })
        .catch(e => {
          console.log(e)
          self.loading = false
        })
        .finally(() => {
          self.loading = false
        })
    },
    async postPerson () {
      const self = this
      const params = {
        clinic_id: self.$globalClinic.id,
        national_id: self.form.national_id,
        cmnd: self.form.cmnd,
        phone: self.form.phone,
        gender: self.form.gender,
        career: self.form.career,
        foreigner: self.form.foreigner,
        address: self.form.address,
        address_district_name: self.form.address_district_name,
        address_province_name: self.form.address_province_name,
        address_wards_name: self.form.address_wards_name,
        work_at: self.form.work_at,
        insurance_type: self.form.insurance_type,
        insurance_start_date: self.form.insurance_start_date,
        emergency_contact: self.form.emergency_contact,
        contact_phone: self.form.contact_phone,
        insurance_rate: self.form.insurance_rate,
        pid: self.form.pid,
        // TODO
        // name: self.form.name.toUpperCase(),
        // birthday: window.moment(self.form.birthday, 'YYYY-MM-DD').unix(),
        // ethnicity: self.form.ethnic_name,
        // insurance_no: self.form.insurance_number,
        // insurance_expire: window
        //   .moment(self.form.insurance_expire, 'YYYY-MM-DD')
        //   .unix(),
        // nationality: self.form.nationality_name,
        // admission_hospital_date: window
        //   .moment(self.form.admission_hospital_date, 'YYYY-MM-DD')
        //   .unix()
        name: self.form.name,
        birthday: self.form.birthday,
        ethnic_name: self.form.ethnic_name,
        insurance_number: self.form.insurance_number,
        insurance_expire: self.form.insurance_expire,
        nationality_name: self.form.nationality_name,
        admission_hospital_date: self.form.admission_hospital_date,
        doc_no: String(this.form.doc_no)
      }
      self.loading = true
      await self.$rf
        .getRequest('DoctorRequest')
        .postPersonsV3(params)
        // TODO
        // .postPatient(params)
        .then(resp => {
          if (resp) {
            self.$emit('refresh', resp.data)
            self.$message({
              message: 'Thêm mới thành công',
              type: 'success'
            })
            self.close()
          }
        })
        .catch(e => {
          console.log(e)
          self.$message({
            message: e,
            type: 'error'
          })
          self.loading = false
        })
        .finally(() => {
          self.loading = false
        })
    },
    openDialog () {
      const defaultForm = {
        name: '',
        national_id: '',
        phone: null,
        birthday: '',
        gender: '',
        career: '',
        ethnic_name: '',
        foreigner: '',
        address: '',
        address_district_name: '',
        address_province_name: '',
        address_wards_name: '',
        work_at: '',
        insurance_type: '',
        insurance_number: '',
        insurance_expire: '',
        insurance_start_date: '',
        emergency_contact: '',
        contact_phone: '',
        nationality_name: '',
        insurance_rate: '',
        pid: '',
        admission_hospital_date: '',
        doc_no: ''
      }
      this.isRequestSamePerson = true
      this.dialogVisible = true
      this.form = defaultForm
    },
    handleClose () {
      const defaultForm = {
        name: '',
        national_id: '',
        cmnd: '',
        phone: null,
        birthday: '',
        gender: '',
        career: '',
        ethnic_name: '',
        foreigner: '',
        address: '',
        address_district_name: '',
        address_province_name: '',
        address_wards_name: '',
        work_at: '',
        insurance_type: '',
        insurance_number: '',
        insurance_expire: '',
        insurance_start_date: '',
        emergency_contact: '',
        contact_phone: '',
        nationality_name: '',
        insurance_rate: '',
        pid: '',
        admission_hospital_date: '',
        doc_no: ''
      }
      this.isRequestSamePerson = true
      this.dialogVisible = false
      this.form = defaultForm
      this.arrExist = [...this.DEFAULT_EXIST]
      this.selectedAddress = {}
    },
    async getAddressVietnam (params) {
      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getAddressVietnam(params)

        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetProvinces () {
      const params = {
        type: ADDRESS_TYPE.province
      }

      const data = (await this.getAddressVietnam(params)) || []

      this.provinces = data?.map(item => ({
        ...item,
        name: item?.name?.trim()
      }))
    },
    async handleGetDistricts () {
      const params = {
        type: ADDRESS_TYPE.district,
        pro_code: this.selectedAddress?.province?.province_id
      }

      const data = await this.getAddressVietnam(params)

      this.districts = data || []
    },
    async handleGetWards () {
      const params = {
        type: ADDRESS_TYPE.ward,
        district_code: this.selectedAddress?.district?.district_id
      }

      const data = await this.getAddressVietnam(params)

      this.wards = data || []
    },
    handleSelectProvince (province) {
      const provinceInfo = this.provinces.find(item => item?.name === province)

      this.selectedAddress.province = provinceInfo

      delete this.selectedAddress.district
      delete this.selectedAddress.ward
      this.form.address_district_name = ''
      this.form.address_wards_name = ''
    },
    handleSelectDistrict (district) {
      const districtInfo = this.districts.find(item => item?.name === district)

      this.selectedAddress.district = districtInfo
      delete this.selectedAddress.ward
      this.form.address_wards_name = ''
    }
  },
  destroyed () {
    this.arrExist = [...this.DEFAULT_EXIST]
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .select-cs {
    .el-input__inner {
      height: 36px !important;
      background-color: #f6f8fb !important;
    }
  }
}

.select-cs {
  min-width: 100%;
  // background-color: #f6f8fb;
}
</style>
