<template>
  <el-dialog width="90%" center :visible.sync="dialogVisible" :before-close="handleClose">
    <!-- body -->
    <div
      class="text-sl"
    >Bệnh nhân đã có thông tin trên hệ thống. Bạn có muốn sử dụng thông tin của bệnh nhân đã có không?</div>
    <div class="text-ml mb-3 italic">(Click chọn bệnh nhân muốn lấy thông tin)</div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-table
        ref="refTagsTable"
        :items="listUser"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Không có dữ liệu"
      >
        <!-- Column: createdAt -->
        <template #cell(action)="data">
          <b-form-radio-group
            id="radio-group-2"
            v-model="selectedPerson"
            name="radio-sub-component"
          >
            <b-form-radio :value="data.item" />
          </b-form-radio-group>
        </template>
        <template #cell(gender)="data">
          <span>{{ getGender(data.item.gender)}}</span>
        </template>
        <template #cell(birthday)="data">
          <span>{{ getYearBorn(data.item) }}</span>
        </template>
        <template #cell(doc_no)="data">
          <span>{{ data.item.doc_no||''}}</span>
        </template>
        <template #cell(phone)="data">
          <span>{{ getPhone(data.item)}}</span>
        </template>
      </b-table>
      <div class="modal-footer border-0 justify-content-center flex">
        <button
          @click="reUsePerson"
          :disabled="!selectedPerson"
          type="button"
          class="btn bg-pri bd-pri text-white upload-btn2 radius-10 w-40"
        >Sử dụng</button>
        <!-- <button
                @click="handleClose"
                type="button"
                class="btn bg-pri bd-pri text-white upload-btn2 radius-10"
              >Tiếp tục thêm mới bệnh nhân
        </button>-->
      </div>
    </b-overlay>
  </el-dialog>
</template>
  
<script>
import {
  BOverlay,
  BRow,
  BFormGroup,
  BFormInput,
  BForm,
  BCol,
  BTable,
  BFormRadio,
  BFormRadioGroup
} from 'bootstrap-vue'

export default {
  name: 'ModalSamePerson',
  components: {
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BOverlay,
    BTable,
    BFormRadio,
    BFormRadioGroup
  },
  data () {
    return {
      dialogVisible: false,
      prescription: [],
      loading: false,
      prId: 0,
      patientVisit: null,
      isDisableUseButton: false,
      selectedPerson: null,
      listUser: [],
      tableColumns: [
        { key: 'action', label: '' },
        { key: 'name', label: 'Tên', sortable: false },
        { key: 'gender', label: 'Giới tính', sortable: false },
        { key: 'birthday', label: 'Năm sinh', sortable: false },
        { key: 'phone', label: 'SĐT', sortable: false },
        { key: 'cmnd', label: 'CMT/CCCD', sortable: false },
        { key: 'pid', label: 'Mã bệnh nhân(PID)', sortable: false },
        { key: 'doc_no', label: 'Mã điều trị', sortable: false }
      ]
    }
  },
  computed: {
    consultationEdit () {
      if (this.patientVisit.consultation) {
        return this.patientVisit.consultation
      } else {
        return {
          reason: '',
          progression: '',
          diagnose: '',
          prehistoric: ''
        }
      }
    }
  },
  methods: {
    getGender (gender = 1) {
      return gender === 1
        ? this.$t('schedule_page.lbl_male')
        : gender === 2
          ? this.$t('schedule_page.lbl_female')
          : gender === 3
            ? this.$t('schedule_page.lbl_other')
            : ''
    },
    async reUsePerson () {
      if (!this.selectedPerson.id) {
        this.$emit('reUsePerson', this.selectedPerson)
        this.handleClose()
      } else {
        await this.$rf
          .getRequest('DoctorRequest')
          .getPersonInfoV2(this.selectedPerson.id)
          .then(resp => {
            if (resp) {
              this.$emit('reUsePerson', resp.data)
              this.handleClose()
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {})
      }
    },
    handleClose () {
      this.selectedPerson = null
      this.dialogVisible = false
      this.$emit('onClose')
    },
    openDialog (object) {
      // this.prId = id
      // this.getPatientVisit(object.id)
      this.listUser = object
      this.dialogVisible = true
    },
    getYearBorn (data) {
      if (!data?.birthday) return ''

      return window.moment(data.birthday).format('YYYY')
    },
    getPhone (item) {
      return typeof item.phone === 'string'
        ? item.phone.replaceAll('.', '').toString()
        : item.phone
    }
  },
  destroyed () {
    this.listUser = []
  }
}
</script>
  
<style lang="scss">
.ck-editor__editable {
  min-height: 5rem;
}
</style>
  