<template>
  <el-dialog
    :title="$t('lbl_reject_connection_request')"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <el-input
      type="textarea"
      :autosize="{ minRows: 2 }"
      :placeholder="$t('lbl_please_specify_the_reason_for_refusing_this_connection_request')"
      v-model="reason"
      class="mb-4"
      style="word-break: break-word;"
    ></el-input>
    <div class="d-flex w-50" style="justify-content: end; margin-left: auto;">
      <button class="btn bd-pri w-100 cl-pri robo-16-400" @click="handleClose">
        <span class="robo-16-500">{{ $t('lbl_cancel') }}</span>
      </button>
      <button class="btn bg-pri bd-pri w-100 text-white ml-2 robo-16-400" @click="confirmReject">
        <span class="robo-16-500">{{ $t('lbl_refuse') }}</span>
      </button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      reason: ''
    }
  },
  methods: {
    openDialog () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    confirmReject () {
      this.$emit('reject', this.reason)
      this.handleClose()
    }
  }
}
</script>