<template>
  <div>
    <form @submit.prevent="onSubmit">
      <div class="card-body py-3">

        <div class="row">
          <div class="col-lg-12">
            <div class="mb-3">
              <label class="form-label required">{{ $t("form.title.phone") }}:</label>
              <input type="text" class="form-control" v-model="form.phone" :disabled="isEdit && form.phone !==''">
              <span class="text-danger font-size-12">{{err_phone}}</span>
            </div>
          </div>

        </div>
        <div class="mb-3">
          <label class="form-label required">{{ $t("form.title.name") }}:</label>
          <input type="text" class="form-control" v-model="form.name" @input="err_name=''">
          <span class="text-danger font-size-12">{{err_name}}</span>
        </div>


        <div class="row">
          <!-- <div class="col-lg-4">
            <div class="mb-3">
              <label class="form-label">{{ $t("form.title.id_card") }}:</label>
              <input type="text" class="form-control" v-model="form.cmnd" >
            </div>
          </div> -->

          <div class="col-lg-5">
            <div class="mb-3">
              <label class="form-label required">{{ $t("form.title.gender") }}:</label>
              <div>
                <label class="form-check form-check-inline">
                  <input type="radio" class="form-check-input" value="1" v-model="form.gender"/>
                  <span class="form-check-label">{{ $t("form.gender.male") }}</span>
                </label>

                <label class="form-check form-check-inline">
                  <input type="radio" class="form-check-input" value="2" v-model="form.gender"/>
                  <span class="form-check-label">{{ $t("form.gender.female") }}</span>
                </label>

                <label class="form-check form-check-inline">
                  <input type="radio" class="form-check-input" value="3" v-model="form.gender"/>
                  <span class="form-check-label">{{ $t("form.gender.other") }}</span>
                </label>
              </div>
              <span class="text-danger font-size-12">{{err_gender}}</span>
            </div>
          </div>

          <div class="col-lg-7">
            <div class="mb-3">
              <label class="form-label required">{{ $t("form.title.bday") }}:</label>
              <div class="row row-sm">
                <div class="col-3">
                  <select v-model="birth.day" class="form-select">
                    <option value="">{{ $t("date.day") }}</option>
                    <option v-for="n in 31" :value="n" :key="n">{{ n }}</option>
                  </select>
                </div>
                <div class="col-5">
                  <select v-model="birth.month" class="form-select" >
                    <option value="">{{ $t("date.month") }}</option>
                    <option value="1">{{ $t("date.month_list.1") }}</option>
                    <option value="2">{{ $t("date.month_list.2") }}</option>
                    <option value="3">{{ $t("date.month_list.3") }}</option>
                    <option value="4">{{ $t("date.month_list.4") }}</option>
                    <option value="5">{{ $t("date.month_list.5") }}</option>
                    <option value="6">{{ $t("date.month_list.6") }}</option>
                    <option value="7">{{ $t("date.month_list.7") }}</option>
                    <option value="8">{{ $t("date.month_list.8") }}</option>
                    <option value="9">{{ $t("date.month_list.9") }}</option>
                    <option value="10">{{ $t("date.month_list.10") }}</option>
                    <option value="11">{{ $t("date.month_list.11") }}</option>
                    <option value="12">{{ $t("date.month_list.12") }}</option>
                  </select>
                </div>
                <div class="col-4">
                  <select v-model="birth.year" class="form-select" @change="err_year=''">
                    <option value="">{{ $t("date.year") }}</option>
                    <option v-for="n in 120" :value="2022 - n" :key="n + 'year'">{{ 2022 - n }}</option>
                  </select>
                  <span class="text-danger font-size-12">{{err_year}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label
          ">{{ $t("form.title.address") }}:</label>
          <input type="text" class="form-control" v-model="form.address" @input="err_address=''">
          <span class="text-danger font-size-12">{{err_address}}</span>
        </div>

        <div class="mb-3" v-if="!isEdit && !isCreateRoom">
          <label class="form-label">{{ $t("title.symptom") }}/{{$t('title.reason_for_visit')}}:</label>
          <textarea class="form-control" v-model="form.symptom"></textarea>
          <span class="text-danger font-size-12">{{err_symptom}}</span>
        </div>

        <div class="mt-4 text-center">
          <button class="btn btn-add btn--blue" type="submit">
            <span class="spinner-border spinner-border-sm mr-2" role="status" v-if="loading"></span>
            <span v-if="!isEdit">
              <span class="fa fa-plus mr-1"></span>{{ $t("button.add_new") }}
            </span>
            <span v-else>{{ $t("button.update") }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>

import appUtils from '../utils/appUtils'
export default {
  name: 'PersonForm',
  props: {
    isEdit: Boolean,
    loading: Boolean,
    birth: Object,
    form: Object,
    isCreateRoom: Boolean
  },
  data () {
    return {
      err_gender: '',
      err_name: '',
      err_phone: '',
      err_year: '',
      err_address: '',
      err_symptom: ''
    }
  },
  mounted () {
    if (!this.isEdit) {
      this.form.gender = 1
      this.form.symptom = ''
    }
  },
  methods: {
    onSubmit () {
      let self = this
      // if (!self.form.name || !self.form.phone || !self.form.gender || !self.birth.year || !self.form.address) {
      //   self.err_name = !self.form.name ? 'Chưa nhập tên!' : ''
      //   self.err_phone = !self.form.phone ? 'Chưa nhập số điện thoại!' : ''
      //   self.err_gender = !self.form.gender ? 'Chưa chọn giới tính!' : ''
      //   self.err_year = !self.birth.year ? 'Chưa chọn năm sinh!' : ''
      //   self.err_address = !self.form.address ? 'Chưa nhập địa chỉ!' : ''
      //   return false
      // }
      if (!self.form.name || !self.form.phone || !self.form.gender || !self.birth.year) {
        self.err_name = !self.form.name ? 'Chưa nhập tên!' : ''
        self.err_phone = !self.form.phone ? 'Chưa nhập số điện thoại!' : ''
        self.err_gender = !self.form.gender ? 'Chưa chọn giới tính!' : ''
        self.err_year = !self.birth.year ? 'Chưa chọn năm sinh!' : ''
        return false
      }
      if (!self.birth.day) {
        self.birth.day = 1
      }
      if (!self.birth.month) {
        self.birth.month = 1
      }
      let data = self.form
      if (self.birth) {
        let day = self.birth.day.toString().padStart(2, 0)
        let month = self.birth.month.toString().padStart(2, 0)
        data.birthday = day + '/' + month + '/' + self.birth.year
      }
      if (appUtils.isDoctor()) {
        data.clinic_id = this.$clinicId
      }
      return self.$emit('onSubmit', data)
    }
  }
}
</script>
<style scoped>
  input[type="text"] {
    height: 60px;
    color: #20419B;
    font-weight: 500px;
  }
</style>
